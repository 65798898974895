import Icons from 'Icons';
import MyButton from 'components/MyButton/MyButton';
import { MyMenuButtonItem } from 'components/MyMenuButton/MyMenuButton';
import MyMenuKebabButton from 'components/MyMenuKebabButton/MyMenuKebabButton';
import MyModal from 'components/MyModal/MyModal';
import PageHeader from 'components/PageHeader/PageHeader';
import { useDialogManager } from 'providers/DialogManager';
import FormValidation from 'providers/FormValidation';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { selectEditModel, setEditModel, updateEditModelField } from 'store/forms.slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import coalesceClassNames from 'utils/coalesceClassNames';
import { generateShortUuid } from 'utils/helpers';
import './MyEditModal.scss';

type EditModalContext<T> = {
    editModel: NonNullable<T>;
    isEditing: boolean;
    isSaving: boolean;
    updateField: (data: Partial<T>) => void;
};
type ChildFunction<T> = (ctx: EditModalContext<T>) => JSX.Element;

export default function MyEditModal<T>({
    title,
    titleContext,
    subtitle,
    close,
    children,
    className,
    isLoading = false,
    isError = false,
    model,
    onSave,
    isSaving = false,
    readonly = false,
    editImmediately = false,
    fullHeight = true,
    headerMenuItems,
    renderFooterSecondary,
    containerSelector,
    withTabs = false,
    disableMenuWhileEditing = true,
    disableBackgroundClose = false,
}: {
    title?: string;
    titleContext?: string;
    subtitle?: string;
    close?: () => void;
    children?: React.ReactFragment | ChildFunction<T>;
    className?: string;
    isLoading?: boolean;
    isError?: boolean;
    model: T;
    onSave?: (model: NonNullable<T>) => Promise<any | void>;
    isSaving?: boolean;
    readonly?: boolean;
    editImmediately?: boolean;
    fullHeight?: boolean;
    headerMenuItems?: (false | MyMenuButtonItem)[];
    renderFooterSecondary?: (ctx: EditModalContext<T>) => React.ReactFragment;
    containerSelector?: string;
    withTabs?: boolean;
    disableBackgroundClose?: boolean;
    disableMenuWhileEditing?: boolean;
}) {
    const [isEditing, setIsEditing] = useState(editImmediately);
    const [isDirty, setIsDirty] = useState(false);

    const editModelId = useRef(generateShortUuid()).current;
    const editModel = useAppSelector(selectEditModel(editModelId));

    const dispatch = useAppDispatch();

    const dialogManager = useDialogManager();
    const handleClose = useCallback(async () => {
        let confirm = true;
        if (isEditing && isDirty) {
            confirm = await dialogManager.confirm({
                title: 'You have unsaved changes',
                message: 'If you exit now your changes will be lost. Are you sure?',
                acceptLabel: 'Yes, exit without saving',
                acceptButtonType: 'Danger',
            });
        }

        if (confirm) {
            close?.();
        }
    }, [isEditing, isDirty, dialogManager, close]);

    /** Keep track of component unmounting to prevent state update errors after the fact
     * this can happen during async save */
    const didUnmount = useRef(false);
    useEffect(() => {
        return () => {
            didUnmount.current = true;
        };
    }, []);

    useEffect(() => {
        dispatch(setEditModel({ id: editModelId, model }));
        return () => {
            // cleanup
            dispatch(setEditModel({ id: editModelId, model: undefined }));
        };
    }, [model, editModelId, dispatch]);

    const startEditing = useCallback(() => {
        setIsEditing(true);
    }, []);

    const updateField = useCallback(
        (data: Partial<T>) => {
            dispatch(
                updateEditModelField({
                    id: editModelId,
                    data,
                }),
            );
        },
        [dispatch, editModelId],
    );

    const saveChanges = useCallback(async () => {
        let didError = false;
        try {
            const result = await onSave?.(editModel);
            if (result?.error) {
                didError = true;
            }
        } catch (e) {
            didError = true;
        }

        if (!didError && !didUnmount.current) {
            setIsEditing(false);
        }
    }, [editModel, onSave]);

    const cancelChanges = useCallback(() => {
        if (editImmediately) {
            // close modal
            close?.();
        } else {
            // stop editing and reset editModel values
            setIsEditing(false);
            dispatch(setEditModel({ id: editModelId, model }));
        }
    }, [close, dispatch, editImmediately, editModelId, model]);

    const ctx = useMemo(
        () => ({
            editModel,
            isEditing,
            isSaving,
            updateField,
        }),
        [editModel, isEditing, isSaving, updateField],
    );

    return (
        <MyModal
            className={coalesceClassNames(
                'MyEditModal',
                withTabs && 'MyEditModal--with-tabs',
                className,
            )}
            close={handleClose}
            disableBackgroundClose={disableBackgroundClose}
            fullHeight={fullHeight}
            isLoading={isLoading}
            isError={isError}
            containerSelector={containerSelector}
        >
            <FormValidation
                onDirtyChanged={setIsDirty}
                submit={saveChanges}
            >
                {({ handleSubmit }) => (
                    <>
                        <div className="MyEditModal__Header">
                            <PageHeader
                                className="MyEditModal__Header__PageHeader"
                                title={title}
                                titleContext={titleContext}
                                subtitle={subtitle}
                            >
                                {!readonly && !editImmediately && (
                                    <MyButton
                                        className="MyEditModal__Header__EditButton"
                                        label="Edit"
                                        buttonType="Accent"
                                        IconLeft={Icons.Edit}
                                        onClick={startEditing}
                                        disabled={isEditing}
                                    />
                                )}
                                {headerMenuItems && headerMenuItems.filter(Boolean).length > 0 && (
                                    <MyMenuKebabButton
                                        className="MyEditModal__Header__MenuButton"
                                        disabled={disableMenuWhileEditing && isEditing}
                                        menuItems={headerMenuItems}
                                    />
                                )}
                            </PageHeader>
                        </div>

                        {/* Avoid rendering form when model is null */}
                        {editModel && (
                            <div className="MyEditModal__FieldsWrapper">
                                {typeof children === 'function' ? children(ctx) : children}
                            </div>
                        )}

                        <div className="MyEditModal__Footer">
                            <div className="MyEditModal__Footer__Secondary">
                                {/* Other action buttons can go here */}
                                {renderFooterSecondary?.(ctx)}
                            </div>
                            <div className="MyEditModal__Footer__Primary">
                                <MyButton
                                    className="MyEditModal__Footer__Primary__Cancel"
                                    label={isEditing ? 'Cancel' : 'Close'}
                                    buttonType="Hollow"
                                    onClick={isEditing ? cancelChanges : close}
                                    disabled={isSaving}
                                />
                                <div
                                    className={coalesceClassNames(
                                        'MyEditModal__Footer__Primary__Save',
                                        isEditing && 'reveal',
                                    )}
                                >
                                    <MyButton
                                        label="Save"
                                        buttonType="Primary"
                                        onClick={handleSubmit}
                                        isLoading={isSaving}
                                        fullWidth
                                    />
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </FormValidation>
        </MyModal>
    );
}
