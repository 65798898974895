import DataTable, {
    ColumnBuilder,
    DataTableColumn,
    DataTableSortDirection,
} from 'components/DataTable/DataTable';
import DataTablePaging from 'components/DataTable/DataTablePaging';
import DataTableCriteria, { CriteriaBuilder } from 'components/DataTableCriteria/DataTableCriteria';
import { useSalesOrderStatusOptions } from 'features/sales/hooks/useSalesOrderStatusOptions';
import { getCustomerDisplayName, SalesOrder } from 'features/sales/models/SalesOrder';
import salesApi, { SalesOrderListParams } from 'features/sales/sales.api';
import { useDataTableDynamicQuery } from 'hooks/useDataTableDynamicQuery';
import React, { useCallback, useMemo } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { formatDateRelative } from 'utils/dateHelpers';
import { formatCurrency } from 'utils/helpers';
import SalesOrderStatusBadge from '../SalesOrderStatusBadge/SalesOrderStatusBadge';
import './SalesOrdersTable.scss';

const COLUMNS = ColumnBuilder<SalesOrder>()
    .column({
        label: 'Order',
        key: 'manufacturerReference',
        isSortable: true,
        defaultSort: 'DESC',
        getValue: item => item.manufacturerReference,
    })
    .column({
        label: 'Group',
        key: 'customerOrderGroup',
        isSortable: true,
        whiteSpace: 'break-spaces',
        getValue: item => item.context.customerOrderGroup.uniqueId,
        renderValue: val => val.split('-').join('\u2011'), // use non-breaking hyphen
    })
    .column({
        label: 'Customer',
        key: 'customer',
        isSortable: false,
        getValue: item => getCustomerDisplayName(item),
    })
    .column({
        label: 'Received',
        key: 'createdAt',
        isSortable: true,
        defaultSort: 'DESC',
        whiteSpace: 'nowrap',
        getValue: item => item.createdAt,
        renderValue: val => formatDateRelative(val),
    })
    .column({
        label: 'Sidemark',
        key: 'sidemark',
        isSortable: true,
        getValue: item => item.context.customerPurchaseOrder.sidemark,
    })
    .column({
        label: 'Status',
        key: 'status',
        isSortable: true,
        getValue: order => order.context.status,
        renderValue: (status, order) => (
            <div className="SalesOrdersTable__StatusCell">
                <SalesOrderStatusBadge
                    status={status}
                    size="small"
                />

                {order.context.isArchived && (
                    <div className="SalesOrdersTable__StatusCell__Archived">Archived</div>
                )}
            </div>
        ),
    })
    .column({
        label: 'Items',
        key: 'itemCount',
        isSortable: true,
        align: 'center',
        getValue: item => item.context.itemCount,
    })
    .column({
        label: 'Sell Price',
        key: 'totalSellPrice',
        isSortable: true,
        align: 'right',
        emptyDash: true,
        getValue: item => item.totalSellPrice,
        renderValue: val => (val ? formatCurrency(val) : ''),
    })
    .column({
        label: 'Tax',
        key: 'totalTax',
        isSortable: true,
        align: 'right',
        emptyDash: true,
        getValue: item => item.totalTax,
        renderValue: val => (val ? formatCurrency(val) : ''),
    })
    .column({
        label: 'Due Date',
        key: 'eta',
        isSortable: true,
        defaultSort: 'DESC',
        whiteSpace: 'nowrap',
        getValue: item => item.eta,
        renderValue: val => (val ? formatDateRelative(val) : ''),
    })
    .build();

export default function SalesOrdersTable() {
    // retain url query params when navigating
    const [searchParams] = useSearchParams();

    // highlight the currently open item id if any
    const { orderId: activeOrderId } = useParams();

    const statusOptions = useSalesOrderStatusOptions();

    const criteriaFields = useMemo(
        () =>
            CriteriaBuilder<SalesOrderListParams>()
                .criteria({
                    label: 'Search',
                    type: 'search',
                    field: 'search',
                    defaultValue: '',
                })
                .criteria({
                    label: 'Received',
                    type: 'date',
                    field: 'dateReceived',
                    range: 'past',
                    defaultValue: '',
                    urlParam: 'received',
                })
                .criteria({
                    label: 'Status',
                    type: 'select',
                    field: 'statusId',
                    options: statusOptions,
                    urlParam: 'status',
                    allowBlank: true,
                    defaultValue: '',
                })
                .criteria({
                    label: 'Archived',
                    type: 'toggle',
                    field: 'isArchived',
                    urlParam: 'archived',
                    defaultValue: 'false',
                })
                .build(),
        [statusOptions],
    );

    const { queryParams, setQueryCriteria, setQuerySort, paging, setQueryPaging } =
        useDataTableDynamicQuery<SalesOrderListParams>(criteriaFields);
    const query = salesApi.useSalesOrderListQuery(queryParams);

    const handleSortChanged = useCallback(
        (col: DataTableColumn<SalesOrder>, direction: DataTableSortDirection) => {
            setQuerySort({
                propertyKey: col.key,
                direction,
            });
        },
        [setQuerySort],
    );

    return (
        <>
            <div className="SalesOrdersTable">
                <div className="SalesOrdersTable__FilterBar">
                    <DataTableCriteria
                        fields={criteriaFields}
                        onChange={setQueryCriteria}
                    />
                </div>

                <DataTable
                    className="SalesOrdersTable__DataTable"
                    isLoading={query.isLoading}
                    isError={query.isError}
                    data={query.data?.data}
                    onRefresh={query.refetch}
                    isRefreshing={query.isFetching}
                    rowLinkTo={item => `${item.id}?${searchParams}`}
                    rowIsHighlighted={item => `${item.id}` === activeOrderId}
                    useFrontEndSorting={false}
                    onSortChanged={handleSortChanged}
                    zebra={true}
                    useStickyHeader={true}
                    columns={COLUMNS}
                    sortUrlParam="sort"
                />

                {(query.data?.total ?? 0) > 0 && (
                    <DataTablePaging
                        data={paging}
                        totalCount={query.data?.total}
                        onChange={setQueryPaging}
                    />
                )}
            </div>
        </>
    );
}
