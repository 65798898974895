import { dateTimeOldSystem } from 'utils/dateSchemas';
import { tryParseJson } from 'utils/helpers';
import z from 'zod';

const SalesOrderHistoryStateSchema = z.object({
    id: z.number(), // 63,
    manufacturer_reference: z.string(), // "000042",
    order_status_id: z.number().nullable(), // 2,
    ordering_form_detail_id: z.number(), // 91714,
    manufacturer_id: z.number(), // 3,
    is_draft: z.number(), // 0,
    manufacturer_order_id: z.number().nullable(), // null,
    created_at: dateTimeOldSystem({ isUTC: true }), // "2023-10-22 14:46:54",
    updated_at: dateTimeOldSystem({ isUTC: true }), // "2023-10-23 02:17:16",
    account_id: z.number(), // 2,
    created_by: z.string().nullable(), // null,
    updated_by: z.string().nullable(), // null,
    notes: z.string().nullable(), // "Notes hereasd",
    total_tax: z.string(), // "35194.5900",
    total_sell_price: z.string(), // "387140.4900",
    total_cost_price: z.string(), // "343445.9000",
    total_freight: z.string(), // "0.0000",
    total_freight_override: z.string(), // "0.0000",
    tracking_information: z.string().nullable(), // null,

    archived_date: dateTimeOldSystem({ isUTC: true, allowBlank: true }).nullable(),
    customer_notes: z.string().nullable(), // null
});

export type SalesOrderHistoryState = z.infer<typeof SalesOrderHistoryStateSchema>;

export const SalesOrderHistoryEntrySchema = z
    .object({
        account_id: z.number(), // 2,
        contact_id: z.number().nullable(), // null,
        contacts_first_name: z.string().nullable(), // null,
        contacts_last_name: z.string().nullable(), // null,
        created_at: dateTimeOldSystem({ isUTC: true }), // "2023-10-24 05:25:47",
        created_by: z.number(), // 1,
        deleted_at: dateTimeOldSystem({ allowBlank: true, isUTC: true }).nullable(), // null,
        documents: z.array(z.any()), // [],
        employees_first_name: z.string(), // "API",
        employees_last_name: z.string(), // "Account",
        event: z.string(), // "create",
        event_item: z.any().nullable(), // null,
        id: z.number(), // 2708074,
        logs: z.string(), // "Downloaded #000042: Order label",
        manufacturer_employee_id: z.number(), // 1,
        manufacturer_employees_first_name: z.string(), // "Edge",
        manufacturer_employees_last_name: z.string(), // "de Guzman",
        model: z.string(), // "App\\Models\\ManufacturerOrder\\ManufacturerOrder",
        model_id: z.number(), // 63,
        new: z.string(), // "{\"id\":63,\"manufacturer_reference\":\"000042\",\"order_status_id\":2,\"ordering_form_detail_id\":91714,\"manufacturer_id\":3,\"is_draft\":0,\"manufacturer_order_id\":null,\"created_at\":\"2023-10-22 14:46:54\",\"updated_at\":\"2023-10-23 02:17:16\",\"account_id\":2,\"created_by\":null,\"updated_by\":null,\"notes\":\"Notes hereasd\",\"total_tax\":\"35194.5900\",\"total_sell_price\":\"387140.4900\",\"total_cost_price\":\"343445.9000\",\"total_freight\":\"0.0000\",\"total_freight_override\":\"0.0000\",\"tracking_information\":null,\"archived_date\":null,\"customer_notes\":null}",
        old: z.string(), // "{\"id\":63,\"manufacturer_reference\":\"000042\",\"order_status_id\":2,\"ordering_form_detail_id\":91714,\"manufacturer_id\":3,\"is_draft\":0,\"manufacturer_order_id\":null,\"created_at\":\"2023-10-22 14:46:54\",\"updated_at\":\"2023-10-23 02:17:16\",\"account_id\":2,\"created_by\":null,\"updated_by\":null,\"notes\":\"Notes hereasd\",\"total_tax\":\"35194.5900\",\"total_sell_price\":\"387140.4900\",\"total_cost_price\":\"343445.9000\",\"total_freight\":\"0.0000\",\"total_freight_override\":\"0.0000\",\"tracking_information\":null,\"archived_date\":null,\"customer_notes\":null}",
        secondary_model: z.any().nullable(), // null,
        secondary_model_id: z.number().nullable(), // null,
        type: z.enum(['order-dl', 'order-update']), // "order-dl",
        updated_at: dateTimeOldSystem({ isUTC: true }), // "2023-10-24 05:25:47",
        updated_by: z.number().nullable(), // 1,
    })
    .transform(model => {
        const activityNew = SalesOrderHistoryStateSchema.parse(tryParseJson(model.new));
        const activityOld = SalesOrderHistoryStateSchema.parse(tryParseJson(model.old));

        return {
            ...model,
            manufacturer_employees_full_name: `${model.manufacturer_employees_first_name} ${model.manufacturer_employees_last_name}`,
            new: activityNew,
            old: activityOld,
        };
    });

export type SalesOrderHistoryEntry = z.infer<typeof SalesOrderHistoryEntrySchema>;
