import { nullableStringToJSONSchema } from 'utils/typeHelpers';
import z from 'zod';
import { SalesOrderLineStatusSchema } from './SalesOrderLineStatus';

export const SalesItemSchema = z.object({
    id: z.number(),
    manufacturerOrderId: z.number(),
    // for updates use this field
    // for display, use context.status
    orderLineStatusId: z.number(),
    orderWindowId: z.number(),
    orderWindowProductId: z.number(),
    inventoryId: z.number(),
    bomLines: z.string().nullable(),
    createdBy: z.number().nullable(),
    customerNotes: z.string().nullable(),
    lineCostPrice: z.number().nullable(),
    lineNumber: z.number(),
    lineSellPrice: z.number().nullable(),
    lineTax: z.number().nullable(),
    notes: z.string().nullable(),
    packingListLines: z.string().nullable(),
    pickingListLines: z.string().nullable(),
    quantity: z.number(),
    systemControlled: z.boolean(),
    unitCostPrice: z.number().nullable(),
    unitSellPrice: z.number().nullable(),
    unitTax: z.number().nullable(),
    updatedBy: z.number().nullable(),
    inventoryOptions: z
        .object({
            measurements: z
                .object({
                    width: z.number().nullable().optional(),
                    height: z.number().nullable().optional(),
                })
                .nullable()
                .optional(),
            options: z
                .array(
                    z.object({
                        name: z.string().nullable(),
                        option_man_name: z.string().nullable(),
                        calculation_key: z.string().optional(),
                        type: z.string(),
                        notes: z.string().nullable(),
                        select_label: z.string().nullable(),
                        amount: z.union([z.string(), z.number()]).nullable(),
                    }),
                )
                .nullable()
                .optional(),
        })
        .nullable(),
    labels: nullableStringToJSONSchema
        .pipe(
            z
                .array(
                    z.object({
                        name: z.string(),
                        value: z.any(),
                    }),
                )
                .nullable(),
        )
        .nullable(),
    context: z.object({
        status: SalesOrderLineStatusSchema,
        product: z.object({
            name: z.string(),
            notes: z.string().nullable(),
        }),
        orderWindow: z.object({
            orderId: z.number(),
            notes: z.string().nullable(),
        }),
        orderWindowProduct: z.object({
            id: z.number(),
        }),
    }),
});
export type SalesItem = z.infer<typeof SalesItemSchema>;
