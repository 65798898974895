import { CircularProgress } from '@mui/material';
import salesApi from 'features/sales/sales.api';
import React from 'react';
import coalesceClassNames from 'utils/coalesceClassNames';
import './SalesItemStatusBadge.scss';
import { SalesOrderLineStatus } from '../../models/SalesOrderLineStatus';

export default function SalesItemStatusBadge({
    statusId,
    status,
    size = 'normal',
    className,
}: {
    statusId?: number;
    status?: SalesOrderLineStatus;
    size?: 'small' | 'normal';
    className?: string;
}) {
    const query = salesApi.useSalesItemStatusesQuery();
    const statusToRender = status ?? query.data?.find(s => s.id === statusId);

    return status == null && query.isLoading ? (
        <CircularProgress size={28} />
    ) : statusToRender ? (
        <div
            className={coalesceClassNames(
                'SalesItemStatusBadge',
                `SalesItemStatusBadge--${size}`,
                className,
            )}
            style={{
                backgroundColor: statusToRender.backgroundColor,
                color: statusToRender.textColor,
            }}
        >
            {statusToRender.name}
        </div>
    ) : (
        <>{statusId}</>
    );
}
