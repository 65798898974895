import MyEditModal from 'components/MyEditModal/MyEditModal';
import PropertyContainer from 'components/PropertyContainer/PropertyContainer';
import PropertyEditDate from 'components/PropertyEditDate/PropertyEditDate';
import PropertyEditMoney from 'components/PropertyEditMoney/PropertyEditMoney';
import PropertyEditSelect from 'components/PropertyEditSelect/PropertyEditSelect';
import PropertyEditText from 'components/PropertyEditText/PropertyEditText';
import SalesOrderCoreStatus from 'features/sales/enums/SalesOrderCoreStatus';
import { useSalesOrderStatusOptions } from 'features/sales/hooks/useSalesOrderStatusOptions';
import { SalesOrder } from 'features/sales/models/SalesOrder';
import salesApi from 'features/sales/sales.api';
import React, { useCallback, useMemo } from 'react';
import './SalesOrderEditModal.scss';

export default function SalesOrderEditModal({
    model,
    close,
}: {
    model?: SalesOrder;
    close?: () => void;
}) {
    const statusOptions = useSalesOrderStatusOptions({
        currentId: model?.orderStatusId,
    });
    const [updateMutation, updateMutationStatus] = salesApi.useSalesOrderUpdateMutation();

    const handleSave = useCallback(
        async (editModel: SalesOrder) => {
            await updateMutation(editModel);
            close?.();
        },
        [close, updateMutation],
    );

    // before the order is cancelled we only show uncancelled statuses
    // after it has been cancelled we only show cancelled statuses
    const notCancelledStatusOptions = useMemo(() => {
        return statusOptions?.filter(o => o.coreStatus !== SalesOrderCoreStatus.Cancelled);
    }, [statusOptions]);

    const cancelledStatusOptions = useMemo(() => {
        return statusOptions?.filter(o => o.coreStatus === SalesOrderCoreStatus.Cancelled);
    }, [statusOptions]);

    const isAlreadyCancelled = useMemo(
        () => cancelledStatusOptions?.find(o => o.value === `${model?.orderStatusId}`),
        [cancelledStatusOptions, model?.orderStatusId],
    );

    const isSaving = updateMutationStatus.isLoading;
    return (
        <MyEditModal
            className="SalesOrderEditModal"
            title="Edit Sales Order"
            titleContext={`${model?.manufacturerReference}`}
            close={close}
            isLoading={!statusOptions}
            // isError={isError}
            model={model}
            onSave={handleSave}
            isSaving={isSaving}
            readonly={false}
            fullHeight={false}
            editImmediately={true}
            containerSelector="#modal-root-top"
        >
            {({ editModel, isEditing, updateField }) => (
                <>
                    <PropertyContainer
                        className="SalesOrderEditModal__PropertyContainer"
                        layout="table"
                        // withInputSpacing
                    >
                        <PropertyEditSelect
                            label="Status"
                            value={`${editModel.orderStatusId}`}
                            onChange={(val: string) => {
                                const orderStatusId = parseInt(val, 10);
                                updateField({ orderStatusId });
                            }}
                            readonly={!isEditing}
                            options={
                                isAlreadyCancelled
                                    ? cancelledStatusOptions
                                    : notCancelledStatusOptions
                            }
                            disabled={isSaving}
                            validationRequired={true}
                        />

                        <PropertyEditDate
                            label="ETA"
                            value={editModel.eta ?? ''}
                            onChange={val => updateField({ eta: val || null })}
                            readonly={!isEditing}
                            disabled={isSaving}
                        />

                        <PropertyEditMoney
                            label="Total freight override"
                            value={editModel.totalFreightOverride ?? editModel.totalFreight}
                            onChange={val => updateField({ totalFreightOverride: val ?? null })}
                            min={0}
                            allowBlank
                        />

                        <PropertyEditText
                            label="Tracking information"
                            value={editModel.trackingInformation ?? ''}
                            onChange={val => updateField({ trackingInformation: val })}
                        />

                        <PropertyEditText
                            label="Internal Notes"
                            hint="Internal only"
                            value={editModel.notes ?? ''}
                            onChange={val => updateField({ notes: val })}
                            multiline={true}
                            readonly={!isEditing}
                            disabled={isSaving}
                        />

                        <PropertyEditText
                            label="External notes"
                            hint="Visible to customer"
                            value={editModel.customerNotes ?? ''}
                            onChange={val => updateField({ customerNotes: val })}
                            multiline={true}
                            readonly={!isEditing}
                            disabled={isSaving}
                        />

                        {/* customer_notes: order.customerNotes,
                    notes: order.notes,
                    order_status_id: order.statusId,
                    total_freight_override: order.totalFreightOverride,
                    tracking_information: order.trackingInformation, */}
                    </PropertyContainer>
                </>
            )}
        </MyEditModal>
    );
}
