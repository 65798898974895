import { rest } from 'msw';

import { faker } from '@faker-js/faker/locale/af_ZA';
import Env from 'config/Env';
import WorkItemStatus from 'features/workOrders/enums/WorkItemStatus';
import WorkItemStepStatus from 'features/workOrders/enums/WorkItemStepStatus';
import { fakeWorkstationSteps } from 'features/workstations/faker/WorkstationsFaker';
import typescriptNaturalSort from 'typescript-natural-sort';
import { fakeSalesOrders } from './faker/SalesFaker';
import { SalesItemLabelConfig } from './models/SalesItemLabelConfig';
import { SalesOrderWorkItem } from './models/SalesOrderWorkItem';

faker.seed(42);
const BASE_URL = `${Env.API_BASE_URL}`;

export const salesHandlers = [
    // Get work items by sales order id
    rest.get(`${BASE_URL}/salesorder/:salesOrderId/workorderitems`, (req, res, ctx) => {
        const workItems = getWorkItems();
        workItems.forEach(wi => {
            delete wi.context.workOrderItemSteps;
        });

        // TODO work out order item ids from data
        // for now im just using some that i know exist in dev
        const result = {
            '242': workItems,
            '243': workItems,
        };
        return res(ctx.delay(500), ctx.status(200), ctx.json(result));
    }),

    // Get work item steps by sales order item id
    rest.get(`${BASE_URL}/salesorderitems/:itemId/workorderitems`, (req, res, ctx) => {
        const result = getWorkItems();
        return res(ctx.delay(500), ctx.status(200), ctx.json(result));
    }),

    rest.post(`${BASE_URL}/salesorder`, (req, res, ctx) => {
        const result = fakeSalesOrders;
        return res(ctx.delay(500), ctx.status(200), ctx.json(result));
    }),

    // Labels
    rest.get(`${BASE_URL}/label-config/sales-order/:orderId`, (req, res, ctx) => {
        const result = { data: getLabels() };
        return res(ctx.delay(500), ctx.status(200), ctx.json(result));
    }),
];

function getWorkItems(): SalesOrderWorkItem[] {
    // group steps by work items
    const result = fakeWorkstationSteps.reduce((arr, step) => {
        let item: SalesOrderWorkItem | undefined = arr.find(
            i => i.id === step.context.workOrderItem.id,
        );
        if (!item) {
            // create a new SalesOrderWorkItem now
            item = {
                id: step.context.workOrderItem.id,
                tuid: step.context.workOrderItem.tuid,
                context: {
                    schedule: step.context.schedule,
                    scheduleMeta: step.context.scheduleMeta,
                    workOrder: step.context.workOrder,
                    workOrderItemState: WorkItemStatus.Unstarted,
                    workOrderItemSteps: [],
                },
            };
            arr.push(item);
        }
        item.context.workOrderItemSteps?.push(step);
        return arr;
    }, [] as SalesOrderWorkItem[]);

    // calculate status
    result.forEach(item => {
        const lastStep =
            item.context.workOrderItemSteps?.[item.context.workOrderItemSteps.length - 1];
        if (
            lastStep?.status === WorkItemStepStatus.Completed ||
            lastStep?.status === WorkItemStepStatus.CompletedLocked
        ) {
            item.context.workOrderItemState = WorkItemStatus.Completed;
        } else if (
            item.context.workOrderItemSteps?.some(
                (step: any) => step.status === WorkItemStepStatus.OnHold,
            )
        ) {
            item.context.workOrderItemState = WorkItemStatus.OnHold;
        } else if (
            item.context.workOrderItemSteps?.some(
                (step: any) =>
                    step.status !== WorkItemStepStatus.Unstarted &&
                    step.status !== WorkItemStepStatus.Skipped &&
                    step.status !== WorkItemStepStatus.SkippedLocked,
            )
        ) {
            item.context.workOrderItemState = WorkItemStatus.InProgress;
        } else {
            item.context.workOrderItemState = WorkItemStatus.Unstarted;
        }
    });

    result.sort((a, b) => typescriptNaturalSort(a.tuid, b.tuid));

    return result;
}

function getLabels(): SalesItemLabelConfig[] {
    return [
        {
            name: 'Test Label',
            id: 1,
            copies: 2,
            copies_user_overridable: true,
            height_in_units: 500.00037795813,
            width_in_units: 800.0006047245,
            units: 'px',
        },
        {
            name: 'Absolute units',
            id: 2,
            copies: 1,
            copies_user_overridable: true,
            height_in_units: 500,
            width_in_units: 800,
            units: 'px',
        },
        {
            name: 'A4',
            id: 3,
            copies: 2,
            copies_user_overridable: false,
            height_in_units: 297,
            width_in_units: 210,
            units: 'mm',
        },
        {
            name: 'A4 Rounding',
            id: 8,
            copies: 1,
            copies_user_overridable: true,
            height_in_units: 297.0012,
            width_in_units: 210.00002,
            units: 'mm',
        },
        {
            name: 'A4 Inches',
            id: 40,
            copies: 1,
            copies_user_overridable: true,
            height_in_units: 11.6929133858,
            width_in_units: 8.2677165354,
            units: 'in',
        },
    ];
}
