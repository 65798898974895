import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import authApi from 'features/auth/auth.api';
import posthog from 'posthog-js';
import type { RootState } from 'store/store';
import { Manufacturer } from './models/Manufacturer';
import { Permission } from './models/Permission';
import { User } from './models/User';

const getInitialState = () => {
    return {
        user: undefined as User | undefined,
        manufacturer: undefined as Manufacturer | undefined,
        // if null it means we aren't sure if we are authed or not
        isAuthenticated: null as boolean | null,
        didSessionExpire: false,
        didLoginError: false,
        permissions: [] as Permission[],
        isUpdateAvailable: false,
    };
};

const handleClearAuth = ({
    didSessionExpire,
}: {
    didSessionExpire?: boolean;
} = {}) => {
    const state = getInitialState();
    state.didSessionExpire = didSessionExpire ?? false;
    state.isAuthenticated = false;
    if (window.POSTHOG_ENABLED) {
        posthog.reset();
    }
    return state;
};

const slice = createSlice({
    name: 'auth',
    initialState: getInitialState(),
    reducers: {
        clearAuth(
            state,
            action: PayloadAction<
                | {
                      didSessionExpire?: boolean;
                  }
                | undefined
            >,
        ) {
            return handleClearAuth(action.payload);
        },
    },
    extraReducers: builder => {
        builder
            // Login succeeded
            .addMatcher(authApi.endpoints.login.matchFulfilled, state => {
                state.isAuthenticated = true;
                state.didSessionExpire = false;
                state.didLoginError = false;
            })
            // Login failed
            .addMatcher(authApi.endpoints.login.matchRejected, state => {
                state.isAuthenticated = false;
                state.didSessionExpire = false;
                state.didLoginError = true;
            })

            // Restore session succeeded
            .addMatcher(authApi.endpoints.restoreSession.matchFulfilled, (state, action) => {
                state.isAuthenticated = true;
                state.permissions = action.payload.permissions;
                state.user = action.payload.user;
                state.manufacturer = action.payload.manufacturer;

                // store manufacture timezone on window so it can be accessed when needed
                // cant really put this in state because it needs to be accessed by utils
                window.MANUFACTURER_TIMEZONE = action.payload.manufacturer.timezone;
            })
            // Restore session failed
            .addMatcher(authApi.endpoints.restoreSession.matchRejected, state => {
                state.isAuthenticated = false;
                state.didSessionExpire = false;
                state.didLoginError = true;
            })
            .addMatcher(authApi.endpoints.checkVersion.matchFulfilled, (state, action) => {
                state.isUpdateAvailable =
                    action.payload.GIT_COMMIT !== process.env.REACT_APP_GIT_HASH;
            });
    },
});

export const { clearAuth } = slice.actions;

export default slice.reducer;

export const selectIsAuthenticated = (state: RootState) => state.auth.isAuthenticated;
export const selectDidSessionExpire = (state: RootState) => state.auth.didSessionExpire;
export const selectDidLoginError = (state: RootState) => state.auth.didLoginError;
export const selectCurrentUser = (state: RootState) => state.auth.user;
export const selectNewVersionAvailable = (state: RootState) => state.auth.isUpdateAvailable;
