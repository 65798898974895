import PropertyContainer from 'components/PropertyContainer/PropertyContainer';
import PropertyDisplay from 'components/PropertyDisplay/PropertyDisplay';
import WorkOrderDetailModal from 'features/workOrders/components/WorkOrderDetailModal/WorkOrderDetailModal';
import { WorkstationStepDetail } from 'features/workstations/models/WorkstationStepDetail';
import { useDialogManager } from 'providers/DialogManager';
import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { formatDateRelative } from 'utils/dateHelpers';
import WorkstationGoToItemModal from '../WorkstationGoToItemModal/WorkstationGoToItemModal';

/** Work order/item information that appears in the WorkstationStepDetailsModal */
export default function WorkstationStepSidebar({
    model,
    currentWorkstationId,
}: {
    model: WorkstationStepDetail;
    currentWorkstationId?: string;
}) {
    const dialogManager = useDialogManager();

    const showRelatedWorkItems = useCallback(async () => {
        if (currentWorkstationId) {
            await dialogManager.custom(WorkstationGoToItemModal, {
                stationId: currentWorkstationId,
                workItemTuid: model?.context.workOrderItem.tuid,
                closeOnSelect: true,
            });
        }
    }, [currentWorkstationId, dialogManager, model?.context.workOrderItem.tuid]);

    const showWorkOrderDetail = useCallback(async () => {
        if (model?.context.workOrder) {
            await dialogManager.custom(WorkOrderDetailModal, {
                workOrderId: model.context.workOrder.id,
                tuid: model.context.workOrder.tuid,
            });
        }
    }, [dialogManager, model?.context.workOrder]);

    return (
        <div className="WorkstationStepSidebar">
            <PropertyContainer>
                <PropertyDisplay
                    label="Work item"
                    value={
                        <a
                            className="Link"
                            onClick={showRelatedWorkItems}
                        >
                            {model.context.workOrderItem.tuid}
                        </a>
                    }
                />

                <PropertyDisplay
                    label="Work order"
                    value={
                        <a
                            className="Link"
                            onClick={showWorkOrderDetail}
                        >
                            {model.context.workOrder.tuid}
                        </a>
                    }
                />

                <PropertyDisplay
                    label="Schedule date"
                    value={
                        <Link
                            to={`/schedule?date=${model.context.schedule.date}&highlight=${model.context.workOrder.id}`}
                            className="Link"
                        >
                            {formatDateRelative(model.context.schedule.date)}
                        </Link>
                    }
                />

                <PropertyDisplay
                    label="Sales order"
                    value={
                        model.context.workOrderItem.salesOrderReference && (
                            <Link
                                to={`/sales/${model.context.workOrderItem.salesOrderId}`}
                                className="Link"
                            >
                                {model.context.workOrderItem.salesOrderReference}
                            </Link>
                        )
                    }
                />

                <PropertyDisplay
                    label="Customer order"
                    value={model.context.workOrderItem.customerOrderReference}
                />

                <PropertyDisplay
                    label="Sidemark"
                    value={model.context.workOrderItem.customerOrderSidemark}
                />
            </PropertyContainer>
        </div>
    );
}
