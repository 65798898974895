import QuoteriteWelcomeLogoSvg from 'assets/quoterite-welcome-logo.svg';
import MyButton from 'components/MyButton/MyButton';
import ForgotPasswordForm from 'features/auth/components/ForgotPasswordForm/ForgotPasswordForm';
import LoginForm from 'features/auth/components/LoginForm/LoginForm';
import React, { useEffect, useState } from 'react';
import './LoginPage.scss';

export default function LoginPage() {
    useEffect(() => {
        document.querySelector('body')?.classList.add('body--LoginPage');
        return () => document.querySelector('body')?.classList.remove('body--LoginPage');
    }, []);

    const [showForgotPassword, setShowForgotPassword] = useState(false);

    const toggleForgot = () => {
        setShowForgotPassword(!showForgotPassword);
    };

    return (
        <div className="LoginPage">
            <img
                className="LoginPage__Logo"
                src={QuoteriteWelcomeLogoSvg}
            />
            {showForgotPassword ? <ForgotPasswordForm close={toggleForgot} /> : <LoginForm />}
            <div className="LoginPage__ForgotWrapper">
                <MyButton
                    key={`showForgotButton_${showForgotPassword}`}
                    // className="Link"
                    buttonType="LinkButton"
                    onClick={toggleForgot}
                >
                    {showForgotPassword ? 'Back to login' : 'Forgot password?'}
                </MyButton>
            </div>
        </div>
    );
}
