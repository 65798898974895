import { faker } from '@faker-js/faker';
import { SalesItem } from 'features/sales/models/SalesItem';
import WorkItemStepStatus from 'features/workOrders/enums/WorkItemStepStatus';
import { DateTime } from 'luxon';
import SalesOrderCoreStatus from '../enums/SalesOrderCoreStatus';
import { SalesOrderDetail } from '../models/SalesOrderDetail';
import { SalesOrderGroup } from '../models/SalesOrderGroup';

faker.seed(42);
const getRandomCount = (min: number, max: number) => faker.number.int({ min, max });
// const companyNames = faker.helpers.multiple(faker.company.name, { count: 20 });
// export const orderStatuses = ['New Order', 'In Progress', 'For Delivery'];

const generateFakeSalesItem = (itemNumber: number): SalesItem => {
    return {
        id: faker.number.int(),
        lineNumber: itemNumber,
        manufacturerOrderId: faker.number.int(), // TODO match the parent order
        quantity: faker.number.int({ min: 1, max: 10 }),
        lineSellPrice: faker.number.float({ min: 100, max: 200 }),
        lineCostPrice: faker.number.float({ min: 50, max: 100 }),
        lineTax: faker.number.float({ min: 50, max: 100 }),
        unitSellPrice: faker.number.float({ min: 100, max: 200 }),
        unitCostPrice: faker.number.float({ min: 50, max: 100 }),
        unitTax: faker.number.float({ min: 50, max: 100 }),
        orderWindowId: faker.number.int(),
        orderWindowProductId: faker.number.int(),
        orderLineStatusId: 1,
        // windowNotes: faker.helpers.maybe(() => faker.word.words()) ?? '',
        inventoryId: faker.number.int(),
        systemControlled: faker.datatype.boolean(),
        createdBy: faker.number.int(),
        customerNotes: faker.helpers.maybe(() => faker.word.words()) ?? '',
        notes: faker.helpers.maybe(() => faker.word.words()) ?? '',
        updatedBy: faker.number.int(),
        pickingListLines: null,
        packingListLines: null,
        bomLines: null,
        inventoryOptions: {
            measurements: {
                width: 2450,
                height: 1250,
            },
            options: [
                {
                    name: 'Fabric Name',
                    option_man_name: 'Fabric Name',
                    select_label: 'TEST MANUFACTURER 2',
                    calculation_key: '',
                    type: 'text',
                    notes: '',
                    amount: '',
                },
                {
                    name: 'Fabric Colour',
                    option_man_name: 'Fabric Colour',
                    select_label: 'Midnight charcoal',
                    calculation_key: '',
                    type: 'text',
                    notes: '',
                    amount: '',
                },
                {
                    name: 'Track Selection',
                    option_man_name: 'Track Selection',
                    select_label: 'Uniline',
                    calculation_key: '',
                    type: 'text',
                    notes: '',
                    amount: '',
                },
                {
                    name: 'Track Number',
                    option_man_name: 'Track Number',
                    select_label: '',
                    calculation_key: '',
                    type: 'number',
                    notes: '',
                    amount: '2',
                },
                {
                    name: 'Control Side',
                    option_man_name: 'Control Side',
                    select_label: 'Left Control',
                    calculation_key: '',
                    type: 'text',
                    notes: '',
                    amount: '',
                },
                {
                    name: 'Bunch Side',
                    option_man_name: 'Bunch Side',
                    select_label: 'Left Bunch',
                    calculation_key: '',
                    type: 'text',
                    notes: '',
                    amount: '',
                },
                {
                    name: 'Notes and Extras',
                    option_man_name: 'Notes and Extras',
                    select_label: '',
                    calculation_key: '',
                    type: 'text',
                    notes: '',
                    amount: '',
                },
                {
                    name: 'Product Notes hidden on quote',
                    option_man_name: 'Product Notes hidden on quote',
                    select_label: '',
                    calculation_key: '',
                    type: 'text',
                    notes: '',
                    amount: '',
                },
            ],
        },
        labels: [
            {
                name: 'Label One',
                value: 'Yes',
            },
            {
                name: 'Something else',
                value: '2049',
            },
        ],
        context: {
            status: {
                id: 1,
                name: 'New Order',
                textColor: '#FFFFFF',
                backgroundColor: '#000000',
                isActive: true,
                sortOrder: 1,
            },
            product: {
                name: 'Test Product',
                notes: '',
            },
            orderWindow: {
                notes: 'Window Notes',
                orderId: faker.number.int(),
            },
            orderWindowProduct: {
                id: 1,
            },
        },
    };
};

export const generateFakeSalesOrder = (): SalesOrderDetail => {
    let itemNumber = 0;
    const dateReceived = DateTime.fromJSDate(faker.date.past({ years: 1, refDate: '2024-01-01' }));
    return {
        id: faker.string.uuid(),
        tuid: `O-${faker.string.numeric({ length: 6 })}`,
        legacyId: faker.number.int(),
        accountId: faker.number.int(),
        manufacturerReference: faker.string.numeric({ length: 6 }),
        totalCostPrice: faker.number.int({ min: 10, max: 2000 }),
        totalFreight: faker.number.int({ min: 1, max: 200 }),
        totalFreightOverride: 0,
        totalSellPrice: faker.number.int({ min: 10, max: 2000 }),
        totalTax: faker.number.int({ min: 1, max: 200 }),
        trackingInformation: null,

        createdAt: dateReceived.toISO() ?? '',
        eta: null,
        orderStatusId: faker.number.int(),

        customerNotes: faker.helpers.maybe(() => faker.word.words()) ?? '',
        notes: faker.helpers.maybe(() => faker.word.words()) ?? '',
        context: {
            isArchived: false,
            orderLines: faker.helpers.multiple(
                () => {
                    itemNumber++;
                    return generateFakeSalesItem(itemNumber);
                },
                { count: getRandomCount(1, 6) },
            ),
            shippingMethod: {
                name: 'Pickup',
            },
            shippingAddress: {
                fullAddress: faker.location.streetAddress({ useFullAddress: true }),
            },
            customerOrderGroup: {
                id: faker.number.int(),
                customerDetailsOverride: faker.helpers.maybe(() => faker.person.fullName()) ?? '',
                uniqueId: `O-${faker.string.numeric({ length: 6 })}`,
                orderNotes: faker.helpers.maybe(() => faker.word.words()) ?? '',
            },
            customerPurchaseOrder: {
                id: faker.number.int(),
                purchaseOrderId: faker.string.numeric({ length: 6 }),
                brandId: faker.number.int({ max: 99 }),
                categoryId: faker.number.int({ max: 99 }),
                splitId: faker.number.int({ max: 99 }),
                sidemark: `SM-${faker.string.numeric({ length: 8 })}`,
            },
            customerConfig: {
                isInternal: faker.datatype.boolean(),
            },
            externalSiteContact: {
                firstName: faker.person.firstName(),
                lastName: faker.person.lastName(),
            },
            externalSiteBusiness: {
                name: faker.company.name(),
            },
            itemCount: faker.number.int(),
            mainCompany: {
                name: faker.company.name(),
            },
            status: {
                id: faker.number.int(),
                name: 'New Order',
                backgroundColor: '#FFFFFF',
                textColor: '#000000',
                isActive: true,
                sortOrder: 1,
                coreStatus: SalesOrderCoreStatus.Unstarted,
            },
        },
    };
};

export const fakeSalesOrderGroups: SalesOrderGroup[] = [];
export const fakeSalesOrders = faker.helpers.multiple(generateFakeSalesOrder, { count: 100 });

// For traffic light display
export const generateFakeSalesOrderWorkOrderItemStep = () => {
    return {
        id: `0000${faker.number.int(9)}`,
        workflowStepId: faker.string.uuid(),
        workOrderItemId: faker.string.uuid(),
        status: WorkItemStepStatus.Unstarted,
        lastTransitionTimestamp: DateTime.now()
            .minus({ days: faker.number.int({ min: 1, max: 7 }) })
            .toISO(),
        lastTransitionReason: undefined,
        context: {
            workflowStep: {
                name: 'Build a thing',
                id: faker.string.uuid(),
            },
        },
    };
};
