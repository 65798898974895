import { CircularProgress } from '@mui/material';
import salesApi from 'features/sales/sales.api';
import React from 'react';
import coalesceClassNames from 'utils/coalesceClassNames';
import './SalesOrderStatusBadge.scss';
import { SalesOrderStatus } from '../../models/SalesOrderStatus';

export default function SalesOrderStatusBadge({
    statusId,
    status,
    size = 'normal',
    className,
}: {
    statusId?: number;
    status?: SalesOrderStatus;
    size?: 'small' | 'normal';
    className?: string;
}) {
    const query = salesApi.useSalesOrderStatusesQuery();
    const statusToRender = status ?? query.data?.find(s => s.id === statusId);

    return status == null && query.isLoading ? (
        <CircularProgress size={28} />
    ) : statusToRender ? (
        <div
            className={coalesceClassNames(
                'SalesOrderStatusBadge',
                `SalesOrderStatusBadge--${size}`,
                className,
            )}
            style={{
                backgroundColor: statusToRender.backgroundColor,
                color: statusToRender.textColor,
            }}
        >
            {statusToRender.name}
        </div>
    ) : (
        <>{statusId}</>
    );
}
