import Layout from 'components/Layout/Layout';
import authApi from 'features/auth/auth.api';
import { selectCurrentUser, selectIsAuthenticated } from 'features/auth/auth.slice';
import CustomerDetailPage from 'pages/CustomersPage/CustomerDetailPage';
import CustomersPage from 'pages/CustomersPage/CustomersPage';
import DocumentDetailPage from 'pages/DocumentsPage/DocumentDetailPage';
import DocumentUploadPage from 'pages/DocumentsPage/DocumentUploadPage';
import DocumentsPage from 'pages/DocumentsPage/DocumentsPage';
import InventoryGroupAddPage from 'pages/InventoryGroupsPage/InventoryGroupAddPage';
import InventoryGroupDetailPage from 'pages/InventoryGroupsPage/InventoryGroupDetailPage';
import InventoryGroupsPage from 'pages/InventoryGroupsPage/InventoryGroupsPage';
import InventoryLocationJoinDetailPage from 'pages/InventoryLocationJoinDetailPage/InventoryLocationJoinDetailPage';
import InventoryAddPage from 'pages/InventoryPage/InventoryAddPage';
import InventoryDetailsPage from 'pages/InventoryPage/InventoryDetailsPage';
import InventoryPage from 'pages/InventoryPage/InventoryPage';
import InventoryReceiptCreatePage from 'pages/InventoryReceivingPage/InventoryReceiptCreatePage';
import InventoryReceiptDetailPage from 'pages/InventoryReceivingPage/InventoryReceiptDetailPage';
import InventoryReceivingPage from 'pages/InventoryReceivingPage/InventoryReceivingPage';
import LocationsPage from 'pages/LocationsPage/LocationsPage';
import LoginPage from 'pages/LoginPage/LoginPage';
import PackageCreatePage from 'pages/PackagesPage/PackageCreatePage';
import PackageDetailPage from 'pages/PackagesPage/PackageDetailPage';
import PackagesPage from 'pages/PackagesPage/PackagesPage';
import ProfilePage from 'pages/ProfilePage/ProfilePage';
import ResetPasswordPage from 'pages/ResetPasswordPage/ResetPasswordPage';
import SalesDetailPage from 'pages/SalesPage/SalesDetailPage';
import SalesItemDetailPage from 'pages/SalesPage/SalesItemDetailPage';
import SalesPage from 'pages/SalesPage/SalesPage';
import SchedulePage from 'pages/SchedulePage/SchedulePage';
import ShipmentCreatePage from 'pages/ShipmentsPage/ShipmentCreatePage';
import ShipmentDetailPage from 'pages/ShipmentsPage/ShipmentDetailPage';
import ShipmentsPage from 'pages/ShipmentsPage/ShipmentsPage';
import StocktakeCountSheetPage from 'pages/StocktakePage/StocktakeCountSheetPage';
import StocktakeCreatePage from 'pages/StocktakePage/StocktakeCreatePage';
import StocktakeDetailPage from 'pages/StocktakePage/StocktakeDetailPage';
import StocktakePage from 'pages/StocktakePage/StocktakePage';
import StocktakeReviewPage from 'pages/StocktakePage/StocktakeReviewPage';
import WorkflowBreakdownPage from 'pages/WorkflowBreakdownPage/WorkflowBreakdownPage';
import CurrentWorkstationPage from 'pages/WorkstationsPage/CurrentWorkstationPage';
import WorkstationStepDetailPage from 'pages/WorkstationsPage/WorkstationStepDetailPage';
import WorkstationsIndexPage from 'pages/WorkstationsPage/WorkstationsIndexPage';
import WorkstationsPage from 'pages/WorkstationsPage/WorkstationsPage';
import React, { Suspense, useEffect } from 'react';
import { Navigate, Outlet, Route, Routes, useParams } from 'react-router-dom';
import { useAppSelector } from 'store/hooks';
import { usePageTitle } from 'utils/locationHelpers';

const HomePage = React.lazy(() => import('pages/HomePage/HomePage'));

export default function AppRoutes() {
    usePageTitle('Quoterite');
    const [restoreSession] = authApi.useLazyRestoreSessionQuery();
    const isAuthed = useAppSelector(selectIsAuthenticated);
    const currentUser = useAppSelector(selectCurrentUser);

    useEffect(() => {
        if (isAuthed === true || isAuthed === null) {
            // if we are authed, or we don't know, try to restore the session
            restoreSession();
        }
    }, [isAuthed, restoreSession]);

    /** Redirect if the user is already logged in */
    const RequiresUnAuthed = () => {
        if (isAuthed) {
            // Important - dont use `useSearchParams` for this logic
            // as it will cause the entire Routes stack to re-render whenever the url changes
            const search = new URLSearchParams(window.location.search);
            const returnUrl = search.get('returnUrl');
            return <Navigate to={returnUrl || '/'} />;
        }
        return <Outlet />;
    };

    /** Redirect if the user is not logged in */
    const RequiresAuthed = () => {
        if (isAuthed === false) {
            // if we are def not authed, go to the login
            // Important - dont use the `useLocation` hook for this logic
            // as it will cause the entire Routes stack to re-render whenever the url changes
            let url = window.location.pathname;
            if (window.location.search) {
                url += `${window.location.search}`;
            }
            return <Navigate to={`/login?returnUrl=${encodeURIComponent(url)}`} />;
        }
        if (isAuthed === true && currentUser != null) {
            // if we are def authed then carry on
            return <Outlet />;
        }
        // Waiting for session restore
        return <Layout isLoading={true} />;

        // TODO error state?
    };

    return (
        <>
            <Suspense fallback={<Layout isLoading={true} />}>
                <Routes>
                    <Route element={<RequiresUnAuthed />}>
                        <Route
                            path="/login"
                            element={<LoginPage />}
                        />
                        <Route
                            path="/reset-password"
                            element={<ResetPasswordPage />}
                        />
                    </Route>
                    <Route element={<RequiresAuthed />}>
                        <Route element={<Layout />}>
                            <Route
                                path="/"
                                element={<HomePage />}
                            />
                            <Route
                                path="/profile"
                                element={<ProfilePage />}
                            />
                            {/* <Route
                                path="/retail-orders"
                                element={<SalesOrderGroupsPage />}
                            /> */}
                            <Route
                                path="/sales"
                                element={<SalesPage />}
                            >
                                <Route
                                    path=":orderId"
                                    element={<SalesDetailPage />}
                                >
                                    <Route
                                        path="items/:itemId"
                                        element={<SalesItemDetailPage />}
                                    />
                                </Route>
                            </Route>
                            {/* <Route
                                path="/work-orders"
                                element={<WorkOrdersPage />}
                            /> */}
                            <Route
                                path="/schedule"
                                element={<SchedulePage />}
                            />
                            <Route
                                path="/workstations"
                                element={<WorkstationsPage />}
                            >
                                <Route
                                    path=":stationId"
                                    element={<CurrentWorkstationPage />}
                                >
                                    <Route
                                        path=":stepId"
                                        element={
                                            <WorkstationStepDetailPage key={useParams().stepId} />
                                        }
                                    />
                                </Route>
                                <Route
                                    path=""
                                    element={<WorkstationsIndexPage />}
                                />
                            </Route>
                            <Route
                                path="/shipments"
                                element={<ShipmentsPage />}
                            >
                                <Route
                                    path="create"
                                    element={<ShipmentCreatePage />}
                                />
                                <Route
                                    path=":shipmentId"
                                    element={<ShipmentDetailPage />}
                                />
                            </Route>
                            <Route
                                path="/packages"
                                element={<PackagesPage />}
                            >
                                <Route
                                    path="create"
                                    element={<PackageCreatePage />}
                                />
                                <Route
                                    path=":packageId"
                                    element={<PackageDetailPage />}
                                />
                            </Route>
                            <Route
                                path="/stocktake"
                                element={<StocktakePage />}
                            >
                                <Route
                                    path="create"
                                    element={<StocktakeCreatePage />}
                                />
                                <Route
                                    path=":stocktakeId"
                                    element={<StocktakeDetailPage />}
                                >
                                    <Route
                                        path=":countSheetKey"
                                        element={<StocktakeCountSheetPage />}
                                    />
                                    <Route
                                        path="review"
                                        element={<StocktakeReviewPage />}
                                    />
                                </Route>
                            </Route>
                            <Route
                                path="/customers"
                                element={<CustomersPage />}
                            >
                                <Route
                                    path=":customerId"
                                    element={<CustomerDetailPage />}
                                />
                            </Route>
                            <Route
                                path="/documents"
                                element={<DocumentsPage />}
                            >
                                <Route
                                    path="upload"
                                    element={<DocumentUploadPage />}
                                />
                                <Route
                                    path=":documentId"
                                    element={<DocumentDetailPage />}
                                />
                            </Route>
                            <Route
                                path="/inventory"
                                element={<InventoryPage />}
                            >
                                <Route
                                    path="add"
                                    element={<InventoryAddPage />}
                                />
                                <Route
                                    path=":inventoryId"
                                    element={<InventoryDetailsPage />}
                                >
                                    <Route
                                        path="location/:locationId"
                                        element={<InventoryLocationJoinDetailPage />}
                                    />
                                </Route>
                                {/* <Route
                                    path=":inventoryId/location/:locationId"
                                    element={
                                        <>
                                            <InventoryDetailsPage />
                                            <InventoryLocationDetailPage />
                                        </>
                                    }
                                /> */}
                            </Route>
                            <Route
                                path="/inventorygroups"
                                element={<InventoryGroupsPage />}
                            >
                                <Route
                                    path="add"
                                    element={<InventoryGroupAddPage />}
                                />
                                <Route
                                    path=":groupId"
                                    element={<InventoryGroupDetailPage />}
                                />
                            </Route>
                            <Route
                                path="/locations"
                                element={<LocationsPage />}
                            />
                            <Route
                                path="/locations/:locationId"
                                element={<LocationsPage />}
                            />
                            <Route
                                path="/receiving"
                                element={<InventoryReceivingPage />}
                            >
                                <Route
                                    path="add"
                                    element={<InventoryReceiptCreatePage />}
                                />
                                <Route
                                    path=":receiptId"
                                    element={<InventoryReceiptDetailPage />}
                                />
                            </Route>
                            <Route
                                path="/locations/:locationId"
                                element={<LocationsPage />}
                            >
                                <Route
                                    path="inventory/:inventoryId"
                                    element={<InventoryLocationJoinDetailPage />}
                                />
                            </Route>
                            <Route
                                path="/workflow/:workflowId/breakdown"
                                element={<WorkflowBreakdownPage />}
                            />
                            <Route
                                path="*"
                                element={<Navigate to={'/'} />}
                            />
                        </Route>
                    </Route>
                </Routes>
            </Suspense>
        </>
    );
}
